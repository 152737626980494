.navbar{
    position: fixed;
    width: 100%;
    display: flex;
    padding: 20px 0;
    background: #292929;
}

.nav-con{
    width: 85%;
    margin: 0 auto;
    padding: 10px 20px;
    background: transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 20px;
}

.logo{
    display: flex;
    gap: 10px;
    align-items: center;
    background: #292929;
}

.logo p{
    font-family:'Satoshi-Medium';  
}

.logo img{
    width: 35px;
    height: 35px;
    object-fit: cover;
    border-radius: 10px;
    background: transparent;
}

.navlinks{
    background: transparent;
}

.navlinks ul{
   display: flex;
   align-items: center;
   gap: 30px;
   background: transparent;
}

.navlinks ul li{
    list-style: none;
    background: transparent;
}

.nav-link{
    font-family:'Satoshi-Regular';
    color: #ECECEC;
    background: transparent;
}

.try-btn{
    display: flex;
    gap: 10px;
}

.try-btn button{
    background: #39B54A;
    color: #fff;
    font-family:'Satoshi-Medium';  
    border: none;
    border-radius: 12px;
    outline: none;
    padding: 10px 15px;
}

.menu-toggle{
    display: none;
    background: transparent;
}

.hamBox{
    position: relative;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.3s ease;
    padding: 1.4em ;
    z-index: 3;
}

.hamBoxOpen{
    background: #292929;
}

.lineTop, .lineBottom{
    margin: 0 auto;
    position: absolute;
    display: block;
    width: 24px;
    height: 2px;
    border-radius: 10px;
    background: #fff;
    left: 0;
    right: 0;
    transform: rotate(0deg);
    transition: all 0.4s;
}

.lineTop{
    top: 1em;
}

.lineBottom{
    bottom: 1em;
}

.lineTop.spin{
    top: 1.4em;
    transform: rotate(135deg);
}

.lineBottom.spin{
    top: 1.4em;
    transform: rotate(225deg);
}


.nav-overlay{
    position: fixed;
    background: #292929;  
    z-index: -2;
    top:-180% ;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    padding: 4em;
    transition: all 2s cubic-bezier(0.16, 1, 0.3, 1);
}


.nav-links{
    background: #292929;  
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    align-items: center;
}

.nav-item{
    width: 100%;
    background: #292929;  
    list-style: none;
    position: relative;
    text-align: center;
}

.nav-item a{
    font-family:'Satoshi-Regular'; 
    background: #292929;  
    color: #fff;
    font-size: 1.2em;
    position: relative;
    top: 0;
    text-align: center;
}


.nav-wrapper:after{
    content: "";
    position: absolute;
    top: 180px;
    left: -10%;
    width: 120%;
    height: 100%;
    margin: 0 auto;
 }
 
@media screen and (max-width:900px){
    .navlinks{
        display: none;
    }
     
    .nav-tab{
        padding: 10px;
        background: #1B224A;
    }

    .nav-con{
        width: 85%;
        margin: 0 auto;
        padding: 10px;
        background: transparent;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .try-btn button{
        display: none;
    }
    

    .menu-toggle{
        display: block;
    }
}