.sub{
    width: 85%;
    margin: 0 auto;
    margin-top: 50px;
}

.sub h2{
    font-family:'Satoshi-Regular';
    font-size:48px ; 
}

.sub-con{
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    gap: 30px;
}

.sub-img{
    width: 100%;
    background: #1A1A1A;
    border-radius: 40px;
    display: flex;
    flex-direction: column;
    gap: 60px;
}

.sub-img img{
    width: 40%;
    background: #1A1A1A;   
}

.sub-text{
    padding:30px 20px;
    background: #1A1A1A;
}

.sub-text p{
    background: #1A1A1A;
    color: #fff;
    font-size: 24px;
    font-family:'Satoshi-Regular'; 
    margin-bottom: 10px; 
}

.sub-text span{
    background: #1A1A1A;
    color: #ececec; 
    font-size: 18px;
    font-family:'Satoshi-Regular';  
}

.sub-box{
    width: 100%;
    background: #1A1A1A;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    border-radius: 40px;
}

.plan-info{
    background: #1A1A1A;  
    padding-top: 20px;
}

.plan-info p{
    font-size: 20px;
    font-family:'Satoshi-Regular';  
    background: #1A1A1A;
    margin-bottom: 10px;
}

.plan-info span{
    color: #ececec;
    font-size: 16px;
    font-family:'Satoshi-Regular'; 
    background: #1A1A1A; 
}

.price-feature{
    display: flex;
    gap: 10px;
    background: #1A1A1A;
    margin-top: 5px; 
}


.price-feature img{
    width: 20px;
    height: 20px;
    object-fit: cover;
}

.price-feature p{
    background: #1A1A1A; 
    font-family:'Satoshi-Regular'; 
    color: #ececec;
}

.sub-box button{
    margin-top: 20px;
    background: #39B54A;
    border: none;
    padding: 12px;
    border-radius: 10px;
    font-family:'Satoshi-Regular'; 
    cursor: pointer;
    transition: all ease-in 0.3s;
}

.sub-box button:hover{
    background: #fff;
    color: #1f1f1f;
    font-family:'Satoshi-Regular'; 
}

@media screen and (max-width:900px){
    .sub h2{
        font-family:'Satoshi-Regular';
        font-size:24px ; 
        text-align: center;
    }
    
    .sub-con{
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        gap: 10px;
    } 

    .sub-img{
        width: 100%;
        background: #1A1A1A;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        gap: 30px;
    }
    
    .sub-box{
        width: 100%;
        background: #1A1A1A;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
        padding: 20px;
        border-radius: 20px;
        text-align: center;
    }
    
    .sub-text{
        text-align: center;
    }

    .sub-text p{
        background: #1A1A1A;
        color: #fff;
        font-size: 20px;
        font-family:'Satoshi-Regular'; 
        margin-bottom: 10px; 
    }
   
    .plan-info{
        text-align: center;
        background: #1A1A1A;  
        padding-top: 20px;
    }

    .sub-box button{
        width: 100%;
    }

  
}