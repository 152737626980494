.work{
    width: 85%;
    margin: 0 auto;
    margin-top: 80px;
}

.work h2{
    font-family:'Satoshi-Regular';
    font-size:48px ; 
}

.work-con{
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
}

.work-left{
    flex-basis: 47%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.work-box{
    padding: 30px;
    background: #292929;
    display: flex;
    gap: 20px;
    border-radius: 20px;
    transition: all ease-in 0.3s;
}


.work-box:hover{
    background: #267C32;  
}

.work-box div{
    background: transparent; 
}

.work-box img{
    width: 60px;
    height: 60%;
    border-radius: 10px;
    object-fit: cover;
}

.work-box p{
    background: transparent;
    color: #fff;
    margin-bottom: 5px;
    font-size: 20px;
    font-family:'Satoshi-Bold'; 
}


.work-box span{
    background: transparent;
    color: #ececec;
    font-size: 18px;
    font-family:'Satoshi-Regular';  
}

.work-right{
    flex-basis: 47%;
}

.work-right img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 60px;
    border: 2px solid #fff;
}

@media screen and (max-width:900px){
    .work h2{
        font-family:'Satoshi-Regular';
        font-size:24px ; 
        text-align: center;
    }
    
    .work-con{
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        gap: 50px;
    }

    .work-left{
        flex-basis: 100%;
    }

    .work-box{
        padding: 20px;
        background: #292929;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
        border-radius: 20px;
        transition: all ease-in 0.3s;
        text-align: center;
    }

    .work-box img{
        width: 30px;
        height: 50%;
        border-radius: 10px;
        object-fit: cover;
    }

    .work-box p{
        background: transparent;
        color: #fff;
        margin-bottom: 5px;
        font-size: 18px;
        font-family:'Satoshi-Bold'; 
    }
    
    
    .work-box span{
        background: transparent;
        color: #ececec;
        font-size: 16px;
        font-family:'Satoshi-Regular';  
    }

    .work-right{
        flex-basis: 100%;
    }
  
    .work-right img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 30px;
        border: 2px solid #fff;
    }
     
}

