.footer{
    width: 85%;
    margin: 0 auto;
    margin-top: 80px;  
}

.pre-footer{
    width: 85%;
    margin: 0 auto;
    text-align: center;
}

.pre-footer h2{
    font-size: 48px;
    font-family:'Satoshi-Regular';
}

.pre-footer p{
    margin-top: 20px;
    font-size: 20px;
    font-family:'Satoshi-Regular';
    color: #ECECEC;
}

.foot-download{
    margin-top: 30px;
    display: flex;
    gap: 5px;
    justify-content: center;
    align-items: center;
}

.footer-con{
    margin-top: 80px;
    margin-bottom: 50px;
    padding: 60px;
    background: #292929;
    border-radius: 40px 40px 0 0;
}

.brief{
    display: flex;
    justify-content: space-between;
    background: #292929; 
    padding-bottom: 70px;
}

.footer-logo {
    flex-basis: 32%;
    background: #292929; 
}

.footer-logo p{
    background: #292929; 
    font-size: 18px;
    font-family:'Satoshi-Bold';
    color: #fff;
    margin-bottom: 10px;
}

.footer-logo span{
    background: #292929; 
    font-size: 18px;
    font-family:'Satoshi-Regular';
    line-height: 24px;
}

.contact-box{
    background: #292929;  
    display: flex; 
    flex-direction: column;
    gap: 10px;
}

.contact-box p{
    background: #292929;  
    font-size: 18px;
    font-family:'Satoshi-Bold';
    color: #fff;
    margin-bottom: 0;
}

.support{
    background: #292929; 
    font-size: 18px;
    font-family:'Satoshi-Regular'; 
    margin-top: 0;
}

.social-links{
    margin-top: 10px;
    background: #292929;  
    display: flex;
    gap: 10px;
}

.social-box{
    background: #292929;   
}

.social-box img{
    background: #292929;   
    width: 100%;
}

.copy{
    background: #292929; 
    display: flex;
    justify-content: space-between;
    padding-top: 30px;
    border-top: 1px solid #ECECEC;
}

.copy p{
    font-size: 18px;
    background: #292929;   
}

.legal{
    background: #292929;   
    display: flex;
    gap: 30px;
}

.legal-link{
    font-size: 18px;
    background: #292929;  
    font-family:'Satoshi-Regular';
}

@media screen and (max-width:900px){
    .footer{
        width: 85%;
        margin: 0 auto;
        margin-top: 50px; 
    }
      
    .pre-footer{
        width: 100%;
        margin: 0 auto;
        text-align: center;
    }

    .pre-footer h2{
        font-size: 24px;
        font-family:'Satoshi-Regular';
    }

    .pre-footer p{
        margin-top: 20px;
        font-size: 16px;
        font-family:'Satoshi-Regular';
        color: #ECECEC;
    }

    .foot-download{
        margin-top: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .footer-con{
        margin-top: 50px;
        margin-bottom: 50px;
        padding: 30px 20px;
        background: #292929;
        border-radius: 30px 30px 0 0;
    }

    .brief{
        display: flex;
        flex-direction: column;
        gap: 30px;
        background: #292929; 
        padding-bottom: 70px;
    }

    .footer-logo p{
        background: #292929; 
        font-size: 16px;
        font-family:'Satoshi-Bold';
        color: #fff;
        margin-bottom: 10px;
    }
    
    .footer-logo span{
        background: #292929; 
        font-size: 16px;
        font-family:'Satoshi-Regular';
        line-height: 24px;
    }

    .contact-box p{
        font-size: 16px;
    }

        
    .social-links{
        background: #292929;  
        display: flex;
        gap: 0px;
    }

    .social-box img{
        background: #292929;   
        width: 70%;
        object-fit: cover;
    }
   
    .copy{
        background: #292929; 
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
        padding-top: 30px;
        border-top: 1px solid #ECECEC;
        gap: 50px;
    }

    .copy p{
        font-size: 16px;
        background: #292929;   
    }

    .legal{
        background: #292929;   
        display: flex;
        flex-direction: column;
        gap: 15px;
    }

    .legal-link{
        font-size: 16px;
        background: #292929;  
        font-family:'Satoshi-Regular';
    }
      
}