*{
  margin: 0;
  padding: 0;    
  text-decoration: none;
  box-sizing: border-box;
  background: #1F1F1F;
  color: #fff;
}

@font-face {
  font-family:'Satoshi-Bold';
  src: url(./Fonts/Satoshi_Complete/Fonts/OTF/Satoshi-Bold.otf);
}

@font-face {
  font-family:'Satoshi-Medium';
  src: url(./Fonts/Satoshi_Complete/Fonts/OTF/Satoshi-Medium.otf);
}


@font-face {
  font-family:'Satoshi-Regular';
  src: url(./Fonts/Satoshi_Complete/Fonts/OTF/Satoshi-Regular.otf);
}

@font-face {
  font-family:'Satoshi-Light';
  src: url(./Fonts/Satoshi_Complete/Fonts/OTF/Satoshi-Light.otf);
}

@font-face {
  font-family:'Satoshi-Black';
  src: url(./Fonts/Satoshi_Complete/Fonts/OTF/Satoshi-Black.otf);
}


.topbar{
  height: 150px;
  background: #1F1F1F;
}

@media screen and (max-width:900px){
  
}