.privacy{
    width: 85%;
    margin: 0 auto;
    padding: 0;
    border-radius: 60px 60px 0 0;
}

.privacy h2{
    border-radius: 20px;
    font-size: 24px;
    font-family:'Satoshi-Regular';
}

.privacy-box{
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 50px;
}

.privacy-box p{
    color: #fff;
    font-size: 20px;
    font-family:'Satoshi-Regular';
}

.privacy-box span{
    color: #ECECEC;
    font-size: 18px;
    line-height: 35px;
    font-family:'Satoshi-Regular';
}

.effective{
    background:transparent ;
    color: #fff;
    font-size: 18px;
    font-family:'Satoshi-Regular';
    margin-top: 30px;
}


@media screen and (max-width:900px){
    .privacy{
        width: 85%;
        margin: 0 auto;
        border-radius: 30px 30px 0 0;
    }  

    .privacy h2{
        border-radius: 20px;
        font-size: 20px;
        font-family:'Satoshi-Regular';
    }

    .privacy-box{
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-top: 50px;
        
    }
    
    .privacy-box p{
        color: #fff;
        font-size: 18px;
        font-family:'Satoshi-Regular';
    }
    
    .privacy-box span{
        color: #ECECEC;
        font-size: 16px;
        line-height: 32px;
        font-family:'Satoshi-Regular';
    }
    
    .effective{
        color: #fff;
        font-size: 16px;
        font-family:'Satoshi-Regular';
        margin-top: 30px;
    }
}