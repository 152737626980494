.feat{
    width: 85%;
    margin: 0 auto;
    margin-top: 80px;
}

.feat h2{
    font-family:'Satoshi-Regular';
    font-size:48px ;
}

.feat-con{
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
}

.feat-left{
    flex-basis: 49%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.feat-box{
    background: #292929;
    padding: 55px 30px;
    border-radius: 40px;
}

.feat-box div{
    width: 60px;
    height: 60px;
    background: #1f1f1f;  
    padding: 5px;
}

.feat-box div img{
    width: 100%;
}

.feat-box p{
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 24px;
    font-family:'Satoshi-Bold';
    background: #292929;
}

.feat-box span{
    background: #292929; 
    font-size: 18px;
    font-family:'Satoshi-Regular'; 
    line-height: 28px;
}

.feat-right{
    flex-basis: 49%;
    padding: 40px 30px;
    background: #39B54A;
    border-radius: 40px;
}

.art-text{
    background: #39B54A;
}

.art-text div{
    width: 60px;
    height: 60px;
    background: #1f1f1f;  
    padding: 5px;
    border-radius: 10px;
}
.art-text div img{
    width: 100%;
}

.art-text p{
    font-size: 24px;
    font-family:'Satoshi-Bold';
    background: #39B54A; 
    margin-top: 20px; 
    margin-bottom: 10px;
}

.art-text span{
    font-size: 18px;
    font-family:'Satoshi-Regular';
    background: #39B54A;
}

.art-image{
    background: linear-gradient(to bottom, #39B54A, #006838);
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    border-radius: 20px;
}

.art-image div{
    background: linear-gradient(to bottom, #39B54A, #006838);
    border-radius: 40px;
}

.art-image div img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
}

@media screen and (max-width:900px){
    .feat h2{
        font-family:'Satoshi-Regular';
        font-size:24px ;
        text-align: center;
    }
   
    .feat-con{
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .feat-box{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 40px  20px;
        border-radius: 20px;
    }

    .feat-box p{
        margin-top: 20px;
        margin-bottom: 10px;
        font-size: 20px;
        font-family:'Satoshi-Bold';
        background: #292929;
    }
    
    .feat-box span{
        background: #292929; 
        font-size: 16px;
        font-family:'Satoshi-Regular'; 
        line-height: 28px;
    }

    .feat-right{
        padding: 40px 20px; 
    }

    .art-text{
       display: flex;  
       flex-direction: column; 
       justify-content: center;
       align-items: center;
       text-align: center;
    }

    .art-text p{
        font-size: 20px;
        font-family:'Satoshi-Bold';
        background: #39B54A; 
        margin-top: 20px; 
        margin-bottom: 10px;
    }
    
    .art-text span{
        font-size: 16px;
        font-family:'Satoshi-Regular';
        background: #39B54A;
    }
 
}

