.hero{
    width: 85%;
    margin: 0 auto;
    text-align: center;
    border-radius: 40px 40px 0 0;
}


.hero h2{
    font-family:'Satoshi-Regular';
    font-size:48px ; 
    width: 80%;
    margin: 0 auto;
}

.hero h2 strong{
    background: -webkit-linear-gradient(#39B54A, #14d179);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}


.hero p{
    width: 60%;
    margin: 0 auto;
    margin-top: 20px;
    font-family:'Satoshi-Regular';
    font-size: 20px;
    color: #ECECEC;
}

.download{
    margin-top: 50px;
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
}

.download-box{
  width: 200px;
  background: #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  border-radius: 20px;
  transition: all ease-in 0.3s;
}

.download-box:hover {
    background: #267C32;  
}

.download-box p{
    width: 100%;
    margin-top: 0;
    font-size: 14px;
    font-family:'Satoshi-Regular';
    background: transparent;   
    color: #ECECEC;
}

.download-box h3{
    width: 100%;
    margin-top: 0;
    margin-bottom: 15px;
    font-size: 40px;
    font-family:'Satoshi-Regular';
    background: transparent;  
    color: #fff;
}

.app-img{
    object-fit: cover;
    background: transparent;  
}

.hero-img{
    width: 100%;
    height: 530px;
    border: 2px solid #fff;
    border-radius: 40px;
    object-fit: cover;

}

.download-box button{
    width: 80%;
    margin: 0 auto;
    margin-top: 10px;
    padding: 10px;
    background: #39B54A;
    border: none;
    border-radius: 10px;
    font-family:'Satoshi-Regular';
    cursor: pointer;
    transition: all ease-in 0.3s;
}

.download-box button:hover{
    background: #fff;
    color: #1f1f1f;
}

.welcome{
    width: 85%;
    margin: 0 auto;
    margin-top: 80px;
    display: flex;
    justify-content: space-between;
}

.welcome div{
    flex-basis: 45%;
}

.welcome h2{
    font-size: 42px;
    font-family:'Satoshi-Regular';
}

.welcome p{
    font-size: 24px;
    font-family:'Satoshi-Regular';
    list-style: 34px;
    color: #ECECEC;
}

@media screen and (max-width:900px){
    .hero{
        width: 85%;
        margin: 0 auto;
        text-align: center;
        border-radius: 30px 30px 0 0;
    }
        
    .hero h2{
        font-family:'Satoshi-Regular';
        font-size:24px ; 
        width: 100%;
    }

    .hero p{
        width: 90%;
        margin: 0 auto;
        margin-top: 14px;
        font-family:'Satoshi-Regular';
        font-size: 20px;
        color: #ECECEC;
    }

    .download{
        margin-top: 50px;
        margin-bottom: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 15px;
    }

    .download-box{
        width: 200px;
        background: #000;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px 0;
        border-radius: 20px;
        transition: all ease-in 0.3s;
      }
      
      .download-box:hover {
          background: #267C32;  
      }
      
      .download-box p{
          width: 100%;
          margin-top: 0;
          font-size: 12px;
          font-family:'Satoshi-Regular';
          background: transparent;   
          color: #ECECEC;
      }
      
      .download-box h3{
          width: 100%;
          margin-top: 0;
          margin-bottom: 5px;
          font-size: 24px;
          font-family:'Satoshi-Regular';
          background: transparent;  
          color: #fff;
      }
      
      .app-img{
          width: 30px;
          height: 50%;
          object-fit: cover;
          background: transparent;  
      }
      
      .download-box button{
          width: 80%;
          margin: 0 auto;
          margin-top: 10px;
          padding: 10px;
          background: #39B54A;
          border: none;
          border-radius: 10px;
          font-size: 12px;
          font-family:'Satoshi-Regular';
          cursor: pointer;
      }
      
      .hero-img{
        width: 100%;
        height: 100%;
        border: 2px solid #fff;
        border-radius: 20px;
        object-fit: cover;   
    }

    .welcome{
        width: 85%;
        margin: 0 auto;
        margin-top: 80px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        gap: 15px;
    }
    
    .welcome div{
        flex-basis: 100%;
    }
    
    .welcome h2{
        font-size: 20px;
        font-family:'Satoshi-Regular';
        text-align: center;
    }
    
    .welcome p{
        font-size: 16px;
        font-family:'Satoshi-Regular';
        list-style: 26px;
        color: #ECECEC;
        text-align: center;
    }
    
}