.choose{
    width: 85%;
    margin: 0 auto;
    margin-top: 80px;
}

.choose h2{
    font-family:'Satoshi-Regular';
    font-size:48px ; 
}

.choose-con{
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    gap: 50px;
}

.choose-box p{
    color: #fff;
    font-size: 20px;
    font-family:'Satoshi-Regular'; 
    margin-top: 10px;
    margin-bottom: 15px;
}

.choose-box span{
    color: #ececec;
    font-size: 18px;
    font-family:'Satoshi-Regular';  
}

@media screen and (max-width:900px){
    .choose h2{
        font-family:'Satoshi-Regular';
        font-size:24px ; 
        text-align: center;
    }
    
    .choose-con{
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 30px;
    }

    .choose-box{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .choose-box p{
        color: #fff;
        font-size: 18px;
        font-family:'Satoshi-Regular'; 
        text-align: center;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    
    .choose-box span{
        color: #ececec;
        font-size: 16px;
        line-height: 26px;
        font-family:'Satoshi-Regular'; 
        text-align: center; 
    }

    .choose-box img{
          width: 40px;
          height: 50%;
          object-fit: cover;
    }
}


